import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import {HttpService} from "./services/http.service";
import {Subscription} from "rxjs";
import {LocalStorageService} from "./services/local-storage.service";
import {FirestoreService} from "./services/firestore.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Admin';
  private url: string;
  private isRestaurant: boolean = false;
  private isRetail: boolean = false;
  private typeBusiness = {
    isRestaurant: false,
    isRetail: false,
  }
  valor: string;
  valorSubscription: Subscription;
  sub: Subscription;

  collectionName = 'Settings';
  collectionField = 'typeBusiness';
  orderByDir: any = 'asc';
  constructor(
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
    private variablesLocales: LocalStorageService,
    private firestore: FirestoreService,
  ) {
  }

  ngOnInit(): void {
    this.sub = this.firestore.search(this.collectionName, {
      'orderByName': this.collectionField,
      'orderByDir': this.orderByDir,
    }).subscribe(res1 => {
      this.getSettings();
    })

    this.valorSubscription = this.variablesLocales.getValorObservable('typeBusiness').subscribe(
      (valor: string) => {
        this.valor = valor;
        console.log('nuevo valor', this.valor);
      }
    );
  }

  getSettings(){
    this.service.sendGET_OneSettingsApp('General').subscribe( res => {
      this.typeBusiness.isRestaurant = res.isRestaurant;
      this.typeBusiness.isRetail = res.isRetail;
      // Convertimos el objeto en una cadena JSON
      let variableJSON = JSON.stringify(this.typeBusiness);
      console.log('variable json',variableJSON)
      localStorage.setItem('typeBusiness', variableJSON);
    })
  }

  ngOnDestroy() {
    this.valorSubscription.unsubscribe();
    this.sub.unsubscribe();
  }

}
