export const environment = {
  production: false,
  currency: '$',

  firebase: {
    apiKey: "AIzaSyA_4GckamYTz5EwmjveV-ZzlRlQs9k1Hvs",
    authDomain: "ianos-app-plaza-mexico-pro.firebaseapp.com",
    projectId: "ianos-app-plaza-mexico-pro",
    storageBucket: "ianos-app-plaza-mexico-pro.appspot.com",
    messagingSenderId: "37269730606",
    appId: "1:37269730606:web:198ff19202b2b9f53c36bf",
    measurementId: "G-7Q7SWGE4WC"
  },
  googleMapsKey: 'AIzaSyCnyj164hLm7drlr85gbQ8tAIQD_ci8KxE',
  urlBase: 'https://us-central1-ianos-app-plaza-mexico-pro.cloudfunctions.neT',
  apikey: '7c038b4dbe9-cb0efb6ad2429-4625-a44bca53',

};
